<template>
  <div class="px-5" id="page-shop_items">
    <h5 class="mb-6 ml-2">{{$t('AUTH.MENU.ORDERS')}}</h5>

    <ShopOrderFormModal
      ref="shop-order-modal"
      :item="selected_item"
      @updated="shop_order_updated"
      @deleted="shop_order_deleted"
    />

    <b-card>
      <ShopOrderTable
        ref="shop_orders_table"
        :shop_orders="shop_orders"
        @shop_order_selected="shop_order_selected"
      />  
    </b-card>
    
  </div>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import ShopOrderTable from '@/view/pages/ml/shop_order/ShopOrderTable.vue';
import ShopOrderFormModal from '@/view/pages/ml/shop_order/ShopOrderFormModal.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ShopOrdersPage',
  mixins: [ toasts ],
  components: {
    ShopOrderTable,
    ShopOrderFormModal
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
  },
  mounted() {
    
  },
  data() {
    return {
      shop_orders: [],
      selected_item: {}
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadPayments(newValue);
      }
    },
  },
  methods: {
    shop_order_updated(shop_order) {

    },

    shop_order_deleted(shop_order_id) {
      
      this.shop_orders = this.shop_orders.filter(item => item.shop_order_id !== shop_order_id);

      this.shop_orders = [ ...this.shop_orders ];

      this.$refs['shop_orders_table'].refresh();

      this.toastr('success', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.DELETED'));
    },

    shop_order_selected(shop_order) {
      this.selected_item = { ...shop_order }

      this.$nextTick(()=>{
        this.$refs['shop-order-modal'].show();
      });
    },

  }
};
</script>
