<template>
  <div id="shop-order-refund-modal">

    <ErrorPopup :error="error" />

    <b-modal ref="edit-modal" size="lg" :ok-title="$t('COMMON.SAVE')" hide-footer>

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('SHOP_ORDER.DETAILS') }}</v-tab>
        <v-tab>{{ $t('SHOP_ORDER.ITEMS') }}</v-tab>

        <v-tab-item>
          <ShopOrderForm
            :item="local_item"
            @created="created_shop_order"
            @updated="updated_shop_order"
            @refunded="refunded_shop_order"
            @cancel="()=>{this.$refs['edit-modal'].hide();}"
            @deleted="deleted_shop_order"
            class="pb-8 pr-8"
          />
        </v-tab-item>

        <v-tab-item>
          
          <ShopOrderItemTable
            v-if="local_item && local_item.shop_order_items"
            :items="local_item.shop_order_items"
            :shop_order="local_item"
            ref="shopOrderItems"
            @select_shop_item="shop_item_selected"
            @edit_shop_item_clicked="edit_shop_item_clicked"
            @delete_shop_item_clicked="delete_shop_item_clicked"
          />


        </v-tab-item>
      </v-tabs>


    </b-modal>

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import ErrorPopup from '@/view/components/ErrorPopup.vue';
import ShopOrderForm from '@/view/pages/ml/shop_order/ShopOrderForm.vue';
import ShopOrderItemTable from '@/view/pages/ml/shop_order/ShopOrderItemTable.vue';

export default {
  name: 'ShopOrderFormModal',
  components: {
    ErrorPopup,
    ShopOrderForm,
    ShopOrderItemTable
  },
  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {

        console.log('new item', this.item);

        this.local_item = { ...this.item };

        if (this.local_item && this.local_item.shop_order_id) {
          this.load_item(this.local_item.shop_order_id)
        }
      }
    }
  },
  mixins: [toasts],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),

  },
  props: ['item'],
  emits: ['created','deleted'],
  async mounted() {
    if (this.item) {
      this.local_item = { ...this.item }

      if (this.local_item && this.local_item.shop_order_id) {
        this.load_item(this.local_item.shop_order_id)
      }
    }
  },
  methods: {

    deleted_shop_order(shop_order_id) {
      this.$refs['edit-modal'].hide();

      this.$emit('deleted', shop_order_id);
    },

    refunded_shop_order() {
      if (this.local_item) {
        this.load_item(this.local_item.shop_order_id);
      }
    },

    async load_item(shop_order_id) {
      try {

        if (!shop_order_id) {
          console.error('load_item shop_order_id is not set', shop_order_id);
          return;
        }

        const res = await axios.get(`/shop_order/${shop_order_id}`);

        if (res.status === 200) {
          this.local_item = res.data;
        }
      }
      catch (err) {
        console.error('load_item error', err);
      }
    },

    shop_item_selected(shop_item_id, name) {},

    edit_shop_item_clicked(shop_item_id) {},

    delete_shop_item_clicked(item) {},

    show() {
      this.$refs['edit-modal'].show();

      this.$nextTick(() => {

      });
    },

    hide() {
      this.$refs['edit-modal'].hide();

      this.$nextTick(() => {

      });
    },

    async created_shop_order(item) {},

    async updated_shop_order(item) {},

    async create_refund() {
      try {
        if (this.local_item.refund_amount <= 0 || this.local_item.refund_amount > this.local_item.amount * 100) {
          this.error = 'ERR_REFUND_AMOUNT_ABOVE_0';
        }

        const res = await axios.post(`/shop_order/refund/${this.local_item.shop_order_id}`, {
          amount: this.local_item.refund_amount,
          reason: this.local_item.refund_reason
        });

        if (res.status === 201) {
          this.$emit('created', res.data);
          this.$refs['edit-modal'].hide();
        }
      }
      catch (err) {
        console.error('create_refund error', err)
      }
    },


  },
  data() {
    return {
      error: null,

      local_item: null

    };
  }

};
</script>
<style lang="scss" scoped>
:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}
</style>
