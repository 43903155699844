<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <MemberShopOrderRefundModal
        ref="refund-modal"
        :shop_order="local_item"

        @created="refund_created"
      />

      <Confirm
        :title="$t('COMMON.DELETE')"
        :message="$t('SHOP_ORDER.CONFIRM_DELETE')"
        ref="confirm_modal"
        @cancel="$refs['confirm_modal'].hide()"
        @confirm="confirm_delete_clicked"
      />

      <!-- Add membership begin -->
      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">

        <p><a target="_blank" :href="link_to">{{ $t('SHOP_ORDER.LINKTO') }}</a></p>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.CREATED_AT')">

              <b-form-input :disabled="true" v-model="local_item.created_at" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.PAYMENT_STATUS')">

              <b-form-select v-model="local_item.payment_status" :options="payment_status_options">
              </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>


        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.PAYMENT_METHOD')">

              <b-form-select :disabled="true" v-model="local_item.payment_method" :options="payment_method_options">
              </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="local_item.dintero_transaction_id">
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.DINTERO_TRANSACTION_ID')">

              <b-form-input :disabled="true" v-model="local_item.dintero_transaction_id" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="local_item.stripe_pi_id">
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.STRIPE_ID')">

              <b-form-input :disabled="true" v-model="local_item.stripe_pi_id" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="local_item.swish_request_id">
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.SWISH_REQUEST_ID')">

              <b-form-input :disabled="true" v-model="local_item.swish_request_id" />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.AMOUNT')">

              <b-form-input :disabled="true" v-model="local_item.amount" />

            </b-form-group>
          </b-col>
        </b-row>


        <div v-if="local_item.refund_amount && local_item.refund_amount > 0">
          <b-row>
            <b-col cols="12">
              <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.REFUND_ID')">

                <b-form-input :disabled="true" v-model="local_item.refund_id" />

              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.REFUND_AT')">

                <b-form-input :disabled="true" v-model="local_item.refund_at" />

              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.REFUND_AMOUNT')">

                <b-form-input :disabled="true" v-model="refund_amount" />

              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label-size="sm" label-cols="4" content-cols="8" :label="$t('SHOP_ORDER.REFUND_REASON')">

                <b-form-input :disabled="true" v-model="local_item.refund_reason" />

              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col cols="4">

          </b-col>
          <b-col cols="8">
            <b-form-group>
            <b-button @click="$refs['refund-modal'].show()" variant="primary" :disabled="disabled_refund_button">
              {{ refund_button_text }}
            </b-button>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-8" v-if="local_item.shop_order_id">
          <b-col cols="4">

          </b-col>
          <b-col cols="8">
            <b-form-group>
              <b-button @click="confirm_delete" variant="danger">
                {{ $t('COMMON.DELETE') }}
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>


        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>

            <RightModalSaveAndCloseButtons :text="$t('COMMON.SAVE')" :spin="true" @clicked="on_submit"
              @close="$emit('cancel')" ref="saveButton" />
          </b-col>
        </b-row>
      </b-form>


    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';


import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import MemberShopOrderRefundModal from '@/view/components/member_editor_v2/sections/member_shop_order/MemberShopOrderRefundModal.vue';

export default {

  name: 'ShopOrderForm',

  props: ['item'],
  emits: ['save_data', 'cancel'],
  mixins: [toasts],

  components: {
    RightModalSaveAndCloseButtons,
    MemberShopOrderRefundModal,
    Confirm
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    }
  },

  // --- begin vuelidate --- //
  setup() {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      local_item: {

      }
    }
  },

  // -- end vuelidate -- //

  computed: {
    refund_amount() {

      const am = parseInt(this.local_item.refund_amount+'');

      if (isNaN(am)) {
        return 0;
      }

      return am / 100;
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),

    link_to() {
      return `${window.location.origin}/x/${this.local_item.linkstr}`;
    },
    
    disabled_refund_button() {
      //return false;

      if (!this.local_item) {
        return true;
      }

      if (this.local_item.payment_method === 'SWISH' && this.local_item.payment_id === null) {
        return true;
      }

      if (this.local_item.swish_request_id === null && this.local_item.stripe_pi_id === null) {
        return true;
      }

      return this.local_item.payment_status !== 'PAID_IN_FULL';
    },

    refund_button_text() {
      if (this.local_item.swish_request_id) {
        return this.$t('SHOP_ORDER.REFUND_SWISH');  
      }

      if (this.local_item.stripe_pi_id && this.local_item.stripe_pi_id.length > 8) {
        return this.$t('SHOP_ORDER.REFUND_STRIPE');
      }

      return this.$t('SHOP_ORDER.NO_REFUND_METHOD');
    }
  },


  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

  },

  methods: {

    confirm_delete() {
      this.$refs['confirm_modal'].show();
    },

    async confirm_delete_clicked() {
      try {
        const res = await axios.delete(`/shop_order/${this.local_item.shop_order_id}`);

        if (res.status === 204) {
          this.$emit('deleted', this.local_item.shop_order_id);
        }
      }
      catch (err) {
        console.error('confirm_delete_clicked', err);
      }
    },


    refund_created(item) {
      this.$emit('refunded')

      this.$refs['refund-modal'].hide();
    },

    validate_state(state) {
      return state.dirty ? !state.error : null;
    },

    async on_submit() {

      if (!this.local_item.shop_order_id) {

      }
      else {
        await this.update_shop_order();
      }

      this.$refs['saveButton'].stop();
    },

    async save_data() {

      this.$emit('save_data', this.local_item);

    },

    async update_shop_order() {
      try {

        const res = await axios.put(`/shop_order/${this.local_item.shop_order_id}`, this.local_item);

        if (res.status === 200) {
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('SHOP_ORDER.UPDATED'));
          this.$emit('save_data', res.data);
          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UPDATE_FAILED'));
    }

  },


  data() {
    return {

      local_item: {},

      paying_options: [
        { value: true, text: this.$t('COMMON.PAID') },
        { value: false, text: this.$t('COMMON.NOT_PAID') }
      ],

      payment_status_options: [
        { value: 'LEGACY', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.LEGACY') },
        { value: 'FREE', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.FREE') },
        { value: 'NOT_PAID', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.NOT_PAID') },
        { value: 'PARTIALLY_PAID', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.PARTIALLY_PAID') },
        { value: 'PAID_IN_FULL', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.PAID_IN_FULL') },
        { value: 'REFUND', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.REFUND') }
      ],

      order_status_options: [
        { value: 'NONE', text: this.$t('SHOP_ORDER.STATUSES.NONE') },
        { value: 'CREATED', text: this.$t('SHOP_ORDER.STATUSES.CREATED') },
        { value: 'WAIT_PAYMENT', text: this.$t('SHOP_ORDER.STATUSES.WAIT_PAYMENT') },
        { value: 'PARTIALLY_PAID', text: this.$t('SHOP_ORDER.STATUSES.PARTIALLY_PAID') },
        { value: 'PAID_IN_FULL', text: this.$t('SHOP_ORDER.STATUSES.PAID_IN_FULL') },
        { value: 'DELETED', text: this.$t('SHOP_ORDER.STATUSES.DELETED') },
        { value: 'FAILED', text: this.$t('SHOP_ORDER.STATUSES.FAILED') },
        { value: 'DONE', text: this.$t('SHOP_ORDER.STATUSES.DONE') },
        { value: 'CANCELLED', text: this.$t('SHOP_ORDER.STATUSES.CANCELLED') }
      ],

      payment_method_options: [
        { value: 'DINTERO', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.DINTERO') },
        { value: 'INVOICE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.INVOICE') },
        { value: 'FORTNOX_INVOICE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.FORTNOX_INVOICE') },
        { value: 'REDEEMCODE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.REDEEMCODE') },
        { value: 'FREE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.FREE') },
        { value: 'UNKNOWN', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.UNKNOWN') },
        { value: 'bgmax', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.bgmax') },
        { value: 'STRIPE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.STRIPE') },
        { value: 'SWISH', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.SWISH') }
      ],

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
